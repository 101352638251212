import React from "react";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import { QueryClientProvider } from "@tanstack/react-query";
import StripeWrapper from "./components/stripe/StripeWrapper";
import PaypalWrapper from "./components/paypal/PaypalWrapper";

const Wrapper = ({ queryClient, children }) => {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <StripeWrapper>
          <PaypalWrapper>{children}</PaypalWrapper>
        </StripeWrapper>
      </QueryClientProvider>
    </Provider>
  );
};

export default Wrapper;
