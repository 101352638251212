import React from "react";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import config from "../../utils/config";
const PaypalWrapper = (props) => {
  return (
    <PayPalScriptProvider
      options={{
        clientId: config.paypalClientId,
      }}
    >
      {props.children}
    </PayPalScriptProvider>
  );
};

export default PaypalWrapper;
