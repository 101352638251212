import { modalConstants } from "../constant";

const { MPSuc, MPHide } = modalConstants;
const iS = {
  isSuc: false,
  isErr: false,
  isL: false,
  list: [],
};

export const ModalReducer = (state = iS, action) => {
  switch (action.type) {
    case MPSuc:
      return { ...state, isHide: false, isSuc: true, data: action.payload };
    case MPHide:
      return { ...state, isHide: true, isSuc: false, data: null };

    default:
      return state;
  }
};
