import React, { useState } from "react";
import Logo from "../assets/logo.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaFacebook, FaInstagram, FaTiktok, FaTwitter } from "react-icons/fa";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
const Footer = () => {
  const [ShowPP, setShowPP] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <div className="">
        <footer
          className="p-3 pt-5 mt-4 border-top"
          style={{ backgroundColor: "#dbdbdb", fontSize: "20px" }}
        >
          <div className="d-flex justify-content-between align-items-end">
            <div className=" col-md-4"></div>

            <div className="col-md-5 row mx-0 p-0 d-md-flex d-none">
              <div className="mb-3 col-md-6 ps-0">
                <h4>Explore</h4>
              </div>
              <div className="mb-3 col-md-6 ps-0">
                <h4>Legal</h4>
              </div>
            </div>
            <div className="mb-3 col-md-3 d-md-block d-none">
              <h4>{t("followUs")}</h4>
            </div>
          </div>

          <div className="row mx-0">
            <div className="col-md-4 ps-0">
              <img
                src={Logo}
                alt="Logo"
                className=" pb-5"
                style={{ width: "200px", height: "160px" }}
              />
            </div>
            <div className="col-md-5 row mx-0 p-0">
              <div className="col-md-6 ps-0">
                <h4 className="d-md-none">Explore</h4>
                <ul className="  list-unstyled">
                  <li className="mb-3">
                    <Link className="text-dark text-decoration-none" to="/">
                      Home
                    </Link>
                  </li>
                  <li className="mb-3">
                    <Link
                      className="text-dark  text-decoration-none"
                      to="/products"
                    >
                      Products
                    </Link>
                  </li>
                  <li className="mb-3">
                    <Link
                      className="text-dark  text-decoration-none"
                      to="/aboutus"
                    >
                      About Us
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="col-md-6 ps-0">
                <h4 className="d-md-none">Legal</h4>

                <ul className="  list-unstyled">
                  <li className="mb-3">
                    <Link
                      className="text-dark text-decoration-none"
                      onClick={() => setShowPP(true)}
                    >
                      {t("Privacy Policy")}
                    </Link>
                  </li>
                  <li className="mb-3">
                    <Link
                      className="text-dark  text-decoration-none"
                      to="/terms_conditions"
                    >
                      {t("Terms And Conditions")}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-3 ps-0">
              <h4 className="d-md-none">Follow Us</h4>
              <div>
                <ul className="nav fs-1 list-unstyled d-flex">
                  <li>
                    <a
                      className="text-dark"
                      target="_blank"
                      rel="noreferrer"
                      href="https://facebook.com/qr?id=100091916702331"
                    >
                      <FaFacebook />
                    </a>
                  </li>
                  <li className="ms-3">
                    <a
                      className="text-dark"
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.instagram.com/impubrymka?igsh=dWVjbWxhNjU5N2d5&utm"
                    >
                      <FaInstagram />
                    </a>
                  </li>
                  <li className="ms-3">
                    <a
                      className="text-dark"
                      target="_blank"
                      rel="noreferrer"
                      href="https://vm.tiktok.com/ZM2a7nKPB/"
                    >
                      <FaTiktok />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="mt-3">
                <h4 className="mb-2">Support</h4>
                <p>
                  {" "}
                  <a href="mailto:info@impubrk.com">info@impubrk.com</a>
                </p>
              </div>
            </div>
          </div>
          <div className="border-top border-dark  text-center">
            <div className="text-dark d-flex py-3 align-items-center justify-content-center">
              © {new Date().getFullYear()} IRK, {t("allRight")}
            </div>
          </div>
        </footer>
      </div>
      <PrivacyPolicy setShow={setShowPP} show={ShowPP} />
    </>
  );
};

export default Footer;
