import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import { useTranslation } from "react-i18next";

const AuthModal = ({ show, setShow }) => {
  const [isForgot, setIsForgot] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <Modal
        centered
        show={show}
        size={`md`}
        onHide={() => setShow(false)}
        scrollable
      >
        <h4 className="text-center py-2">
          {isForgot ? (
            <>{t("login.forgot_password")}</>
          ) : (
            <>{t("login.title")}</>
          )}
        </h4>
        <Modal.Body>
          {/* <div
            className="row w-100 shadow-sm  rounded p-0 mx-0 "
            style={{
              width: "max-content",
              border: "1px solid gray",
            }}
          >
            <button
              type="button"
              className={`rounded col-md-6  p-2  border-0  ${
                !isReg ? "fadeInRight " : "bg-transparent"
              }`}
              onClick={(e) => setIsReg(false)}
            >
              SignIn
            </button>
            <button
              type="button"
              className={`rounded col-md-6  p-2 border-0 ${
                isReg ? " fadeInLeft" : "bg-transparent"
              }`}
              onClick={(e) => setIsReg(true)}
            >
              SignUp
            </button>
          </div> */}
          {isForgot ? (
            <ForgotPassword setShow={setShow} />
          ) : (
            <Login setShow={setShow} setIsForgot={setIsForgot} />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AuthModal;
