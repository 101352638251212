import { configureStore } from "@reduxjs/toolkit";

import { UsersReducer } from "./reducers/usersReducer";

import { ProductsReducer } from "./reducers/productsReducer";
import { AuthReducer } from "./reducers/authReducer";

import { ModalReducer } from "./reducers/modalReducer";
import { localStoreReducer } from "./reducers/localStoreReducer";
import { OrderReducer } from "./reducers/ordersReducer";

export const store = configureStore({
  reducer: {
    user: UsersReducer,

    product: ProductsReducer,
    auth: AuthReducer,
    modal: ModalReducer,
    localStore: localStoreReducer,
    order: OrderReducer,
  },
});
