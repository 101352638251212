import { authConstants } from "../constant";

const { AUReq, AUErr, AUSuc, AUClr } = authConstants;
const iS = {
  isSuc: false,
  isErr: false,
  isL: false,
  list: [],
};

export const AuthReducer = (state = iS, action) => {
  switch (action.type) {
    case AUSuc:
      return {
        ...state,
        isErr: false,
        isSuc: true,
        isL: false,
        data: action.payload,
      };
    case AUReq:
      return { ...state, isErr: false, isSuc: false, isL: true };
    case AUErr:
      return { ...state, isErr: true, isSuc: false, isL: false };
    case AUClr:
      return { ...state, isErr: false, isSuc: false, isL: false, data: null };

    default:
      return state;
  }
};
