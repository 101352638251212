import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-multi-carousel/lib/styles.css";
import "react-toastify/dist/ReactToastify.css";
import "animate.css/animate.min.css";
import "rsuite/dist/rsuite.min.css";
import { ToastContainer } from "react-toastify";
import "./utils/i18n";
import { QueryClient } from "@tanstack/react-query";
import Wrapper from "./Wrapper";
export const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Wrapper queryClient={queryClient}>

    <App />


    <ToastContainer />
  </Wrapper>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
