import React from "react";
import { connect } from "react-redux";
import Loader from "../Loader";
import { useQuery } from "@tanstack/react-query";
import api from "../utils/api";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";

const PrivacyPolicy = ({ lang, setShow, show }) => {
  const { data, isLoading } = useQuery({
    queryKey: ["privacy_policy"],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      let res = await api.get("/settings/privacy_policy", {
        headers: { authorization: `${localStorage.getItem("token")}` },
      });
      return res.data;
    },
  });
  const { t } = useTranslation();
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="fs-4 mb-3  text-dark fw-semibold">
            {t("Privacy Policy")}
          </div>
        </Modal.Title>
      </Modal.Header>

      {isLoading ? (
        <Loader />
      ) : data ? (
        <div
          className="p-4"
          dangerouslySetInnerHTML={{ __html: data[`${lang}_text`] }}
        />
      ) : (
        <div className="text-center w-100 h-100">nothing found</div>
      )}
    </Modal>
  );
};
const mapStateToProps = (state) => {
  return {
    lang: state.localStore.data,
  };
};
export default connect(mapStateToProps, null)(PrivacyPolicy);
