// test
// const config = {
//   stripePublishableKey:
//     "pk_test_51NZFVXCP7P4iKlNGn34LV6X2QVlwKU90JcYb6UAJkmS9ExMoFxBP8lCVNKzY3WEB9VBVtWawrTZgMCBMlwmCuGES00AM1XHrQ4",
//   paypalClientId:
//     "AavfhYk3utsDvTERmeRloSEiXtK5d_R5lJ8AEHnf7gn_krRP_NhcqihVym6TCvgGtRIZxNoXS1-b0wd1",
// };

//production
const config = {
  stripePublishableKey:
    "pk_live_51NZFVXCP7P4iKlNG4d2iu3qg8jVKq271LhK5lynnLtjZ9KJo8bYm9FxJkydKXurZF49vF0nonCtFHgFJilSl1rzq005qzWUg02",
  paypalClientId:
    "ARorf49Qx3g2Lr-r3edPNamGd8x1gX2oMiqbsFYV_rrafOM6WIQIJFwSLsndmz6BXOcMLvUyLhMUeaDc",
};
export default config;
