import { localStoreConstant } from "../constant";
const { lang, cartS } = localStoreConstant;
const iS = {
  list: [],
  data: "en",
};

export const localStoreReducer = (state = iS, action) => {
  switch (action.type) {
    case lang:
      return { ...state, data: action.payload };
    case cartS:
      return { ...state, list: action.payload };
    default:
      return state;
  }
};
