import React, { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import AntForm from "../AntForm";

const UpdatePassword = lazy(() => import("../Pages/UpdatePassword"));

const HomePage = lazy(() => import("../Pages/Homepage"));
const ProductsPage = lazy(() => import("../Pages/ProductsPage"));
const ViewProductPage = lazy(() =>
  import("../Pages/ProductsPage/ViewProductPage")
);

const CustomProductPage = lazy(() =>
  import("../Pages/ProductsPage/CustomProductPage")
);
const AboutUsPage = lazy(() => import("../Pages/aboutusPage"));
const ContactusPage = lazy(() => import("../Pages/contactusPage"));

const OrdersPage = lazy(() => import("../Pages/ordersPage"));
const PaymentPage = lazy(() => import("../Pages/PaymentPage"));
const TermsAndConditionPage = lazy(() => import("../Pages/TermsAndCondition"));
const PrivacyPolicyPage = lazy(() => import("../Pages/PrivacyPolicy"));

const Registration = lazy(() => import("../components/auth/Register"));
const EditProfilePage = lazy(() =>
  import("../components/orders/UpdateProfile")
);

const MainRoutes = ({ isUser }) => {
  return (
    <>
      <Routes>
        <Route
          path="/AntForm"
          element={
            <>
              <AntForm />
            </>
          }
        />
        <Route
          path="/registration"
          element={
            <>
              <NavBar isUser={isUser} />
              <Registration />
              <Footer />
            </>
          }
        />
        <Route
          path="/"
          element={
            <>
              <NavBar isUser={isUser} />
              <HomePage />
              <Footer />
            </>
          }
        />
        <Route
          path="/reset_password/:tokenId/:userId"
          element={
            <>
              <NavBar />
              <UpdatePassword />
              <Footer />
            </>
          }
        />
        <Route
          path="/products"
          element={
            <>
              <NavBar isUser={isUser} />
              <ProductsPage />

              <Footer />
            </>
          }
        />
        <Route
          path="/products/:id"
          element={
            <>
              <NavBar isUser={isUser} />
              <ViewProductPage />
              <Footer />
            </>
          }
        />
        <Route
          path="/products/custom/:id"
          element={
            <>
              <NavBar isUser={isUser} />
              <CustomProductPage />
              <Footer />
            </>
          }
        />
        <Route
          path="/aboutus"
          element={
            <>
              <NavBar isUser={isUser} />
              <AboutUsPage />
              <Footer />
            </>
          }
        />
        <Route
          path="/contactus"
          element={
            <>
              <NavBar isUser={isUser} />
              <ContactusPage />

              <Footer />
            </>
          }
        />
        <Route
          path="/terms_conditions"
          element={
            <>
              <NavBar isUser={isUser} />
              <TermsAndConditionPage />

              <Footer />
            </>
          }
        />
        <Route
          path="/privacy_policy"
          element={
            <>
              <NavBar isUser={isUser} />
              <PrivacyPolicyPage />

              <Footer />
            </>
          }
        />
        {isUser && (
          <>
            <Route
              path="/orders"
              element={
                <>
                  <NavBar />
                  <OrdersPage />
                  <Footer />
                </>
              }
            />

            <Route
              path="/profile/edit"
              element={
                <>
                  <NavBar />
                  <EditProfilePage />
                  <Footer />
                </>
              }
            />
            <Route
              path="/payment"
              element={
                <>
                  <NavBar />
                  <PaymentPage />
                  <Footer />
                </>
              }
            />
          </>
        )}{" "}
        {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
      </Routes>
    </>
  );
};

export default MainRoutes;
