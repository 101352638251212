import React, { useState, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../assets/logo.png";
import { useTranslation } from "react-i18next";
import ReactFlagsSelect from "react-flags-select";
import Offcanvas from "react-bootstrap/Offcanvas";

import { FaUser, FaShoppingCart } from "react-icons/fa";

import AddToCarts from "./carts";
import { connect } from "react-redux";
import { langStore } from "../redux/actions/storeActions";
import AuthModal from "./auth/AuthModal";
import { Dropdown } from "antd";
import IconUser from "../assets/user.png";
import { clearLabel } from "../redux/actions/authActions";
const NavBar = ({ langStore, clearLabel, lang, isSuc, authd, isUser }) => {
  const [select, setSelect] = useState("FR");
  const [auth, setAuth] = useState(false);
  const onSelect = (code) => setSelect(code);
  const { t } = useTranslation();
  const [CartM, setCartM] = useState(false);
  const { i18n } = useTranslation();
  const path = useLocation()?.pathname?.split("/").pop();
  const location = useLocation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    langStore(lng);
  };
  const history = useNavigate();
  useEffect(() => {
    if (select) {
      if (select === "US") {
        i18n.changeLanguage("en");
        langStore("en");
      } else {
        i18n.changeLanguage("fr");
        langStore("fr");
      }
    }
  }, [select]);
  useEffect(() => {
    setCartM(false);
  }, [location]);

  let items = [
    {
      key: "profile",
      label: "Profile",
    },
    {
      key: "logout",
      label: "Logout",
    },
  ];
  const onClick = async ({ key }) => {
    if (key === "profile") {
      if (authd?.type === "admin") {
        history("/admin/users");
      } else {
        history("/orders");
      }
    } else {
      localStorage.removeItem("token");
      await clearLabel();
      history("/");
    }
  };
  return (
    <>
      <Navbar bg="light" expand={"md"} className="mb-3 shadow">
        <>
          <Navbar.Toggle
            aria-controls={`offcanvasNavbar-expand-md`}
            className="btn ms-1"
          />

          <Navbar.Brand className="col-md-2 col-6 text-center m-0 py-2 ">
            <Link to="/" className="">
              <img
                src={Logo}
                alt="logo"
                style={{ width: "100px", height: "70px" }}
              />
            </Link>
          </Navbar.Brand>
          <button
            type="button"
            className="border-0 bg-transparent p-0 m-0 me-2 d-md-none text-dark"
            onClick={() => setCartM(true)}
          >
            <FaShoppingCart style={{ width: "24px", height: "24px" }} />
          </button>
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-md`}
            aria-labelledby={`offcanvasNavbarLabel-expand-md`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
                <ReactFlagsSelect
                  selected={select}
                  className="border-0 mb-0 pb-0"
                  customLabels={{ US: "ENGLISH", FR: "Français" }}
                  onSelect={onSelect}
                  countries={["US", "FR"]}
                />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="row mx-0 col-12   align-items-center">
                <Nav className=" text-center   row mx-0  px-md-2 px-0  row-cols-lg-4 col-md-8 col-12 align-items-center">
                  <Link
                    className={`d-block ${
                      !path ? "menuLinkActive" : ""
                    } menuLink  py-3 fw-semibold rounded  col text-capitalize text-decoration-none`}
                    to="/"
                  >
                    {t("nav.home")}
                  </Link>{" "}
                  <Link
                    className={`d-block ${
                      path.includes("products") ? "menuLinkActive" : ""
                    } menuLink py-3 fw-semibold rounded  col text-capitalize text-decoration-none`}
                    to="/products"
                  >
                    {t("nav.products")}
                  </Link>{" "}
                  <Link
                    className={`d-block ${
                      path.includes("contactus") ? "menuLinkActive" : ""
                    } menuLink py-3 fw-semibold rounded  col text-capitalize text-decoration-none`}
                    to="/contactus"
                  >
                    {t("nav.quotation")}
                  </Link>{" "}
                  <Link
                    className={`d-block ${
                      path.includes("aboutus") ? "menuLinkActive" : ""
                    } menuLink py-3 fw-semibold rounded  col text-capitalize text-decoration-none`}
                    to="/aboutus"
                  >
                    {t("nav.aboutus")}
                  </Link>
                </Nav>
                <div className="d-md-block d-none col-md-2">
                  <ReactFlagsSelect
                    selected={select}
                    className="border-0 mb-0 pb-0"
                    customLabels={{ US: "ENGLISH", FR: "Français" }}
                    onSelect={onSelect}
                    countries={["US", "FR"]}
                  />
                </div>
                <div className="col-md-2 d-flex align-items-center justify-content-evenly">
                  {isSuc ? (
                    <Dropdown menu={{ items, onClick }} placement="bottom">
                      <button
                        type="button"
                        onClick={(e) => e.preventDefault()}
                        className="border-0 bg-transparent"
                      >
                        <img
                          src={IconUser}
                          alt="IconUser"
                          style={{ width: "28px", height: "28px" }}
                        />
                      </button>
                    </Dropdown>
                  ) : (
                    <button
                      type="button"
                      onClick={() => setAuth(true)}
                      className="border-0 bg-transparent p-0 m-0  text-dark"
                    >
                      <FaUser style={{ width: "24px", height: "24px" }} />
                    </button>
                  )}

                  <button
                    type="button"
                    className="border-0 bg-transparent p-0 m-0 ms-1 d-md-block d-none text-dark"
                    onClick={() => setCartM(true)}
                  >
                    <FaShoppingCart style={{ width: "24px", height: "24px" }} />
                  </button>
                </div>
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </>
      </Navbar>

      {CartM && (
        <AddToCarts setShow={setCartM} show={CartM} lang={lang} authd={authd} />
      )}
      {auth && <AuthModal show={auth} setShow={setAuth} />}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    lang: state.localStore.data,
    isSuc: state.auth.isSuc,
    authd: state.auth.data,
  };
};
export default connect(mapStateToProps, { langStore, clearLabel })(NavBar);
