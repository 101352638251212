import { ToastModal } from "../../components/toast";
import api from "../../utils/api";

import { authConstants } from "../constant";
// import {Token } from './token';

const { AUReq, AUErr, AUSuc, AUClr } = authConstants;
export const clearLabel = () => async (dispatch) => dispatch({ type: AUClr });

export const loginUser = (data) => async (dispatch) => {
  dispatch({ type: AUReq });
  try {
    let res = await api.get("/users/login", { params: data });
    if (res.data.token && !res.data.error) {
      localStorage.setItem("token", res.data.token);
      dispatch({ type: AUSuc, payload: res.data.user });
    } else {
      localStorage.removeItem("token");
      dispatch({ type: AUErr });
      ToastModal(res.data.error, "error");
    }
  } catch (error) {
    localStorage.removeItem("token");
    dispatch({ type: AUErr });
    console.log("can not do", error);
  }
};
export const setUser = (data) => async (dispatch) => {
  try {
    dispatch({ type: AUReq });

    if (data.user && !data.error) {
      dispatch({ type: AUSuc, payload: data.user });
    } else {
      dispatch({ type: AUErr });
      ToastModal(data.error, "error");
    }
  } catch (error) {
    dispatch({ type: AUErr });
    console.log("can not do");
  }
};
export const getSuperAdmin = async (data) => {
  try {
    let res = await api.get("/auth/getSuperAdmin", {
      headers: { authorization: `${localStorage.getItem("token")}` },
    });

    if (res.data.user && !res.data.error) {
      return res.data.user;
    }
  } catch (error) {
    console.log("can not do");
  }
};

export const getLoggedUser = (data) => async (dispatch) => {
  try {
    dispatch({ type: AUReq });
    let res = await api.get("/users/getLoggedUser", {
      headers: { authorization: `${localStorage.getItem("token")}` },
    });
    if (res.data && !res.data.error) {
      dispatch({ type: AUSuc, payload: res.data });
    } else {
      localStorage.removeItem("token");
      dispatch({ type: AUErr });
      ToastModal(res.data.error, "error");
    }
  } catch (error) {
    localStorage.removeItem("token");
    dispatch({ type: AUErr });
    console.log("can not do");
  }
};

export const updateSuperUser = (data, file) => async (dispatch) => {
  try {
    let res = await api.post("/users/updateSuperAdmin", data, {
      headers: { authorization: `${localStorage.getItem("token")}` },
    });
    if (res?.data && !res.data.error) {
      ToastModal("Profile has been updated", "success");
    } else {
      ToastModal(res.data.error, "error");
    }
  } catch (error) {
    console.log("error", error.message);
  }
};

export const getAllAccess = async (data) => {
  try {
    let res = await api.get("/auth/getAllAccess", {
      params: data,
      headers: { authorization: `${localStorage.getItem("token")}` },
    });
    if (res.data && !res.data.error) {
      return res.data;
    }
  } catch (error) {
    console.log("error", error.message);
  }
};
export const getAllAccess2 = async (data) => {
  try {
    let res = await api.get("/auth/getAllAccess2", {
      params: data,
      headers: { authorization: `${localStorage.getItem("token")}` },
    });
    if (res.data.access && !res.data.error) {
      return res.data.access;
    }
  } catch (error) {
    console.log("error", error.message);
  }
};
export const updateAccess = async (data) => {
  try {
    let res = await api.post("/auth/updateAccess", data, {
      headers: { authorization: `${localStorage.getItem("token")}` },
    });
    if (res.data && !res.data.error) {
      ToastModal("Access has been updated", "success");
    } else {
      ToastModal(res.data.error, "error");
    }
  } catch (error) {
    console.log("error", error.message);
  }
};

export const logoutUser = (data) => async (dispatch) => {
  try {
    await api.post("/users/logout", data, {
      headers: { authorization: `${localStorage.getItem("token")}` },
    });
    localStorage.removeItem("token");
    window.location.href = "/login";
    dispatch({ type: AUErr });
  } catch (error) {
    localStorage.removeItem("token");
    window.location.href = "/login";
    dispatch({ type: AUErr });
    console.log("error", error.message);
  }
};
