import React from "react";

import "./loader.css";
const Loader = () => {
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ height: "100vh" }}
    >
      <span className="loader"></span>
    </div>
  );
};

export default Loader;
