import { useMutation } from "@tanstack/react-query";
import React, { useState } from "react";
import { ToastModal } from "../toast";
import api from "../../utils/api";

const ForgotPassword = ({ setShow }) => {
  const [email, setEmail] = useState("");
  const { mutate, isLoading } = useMutation({
    mutationFn: (newTodo) => {
      if (newTodo) {
        return api.put("/users/forget_password", { ...newTodo });
      }
    },
    onSuccess: ({ data }) => {
      if (data.error) {
        ToastModal(data.error, "error");
      } else {
        setShow(false);
        ToastModal("Please check your email", "info");
      }
    },
  });
  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          mutate({ email });
        }}
        className="p-3"
      >
        <div className="mb-3">
          <label htmlFor="ForgotPasswordInputEmail1" className="form-label">
            Email address
          </label>
          <input
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            maxLength={50}
            required
            className="form-control"
            id="ForgotPasswordInputEmail1"
            aria-describedby="emailHelp"
          />
        </div>

        <div className="w-100 mx-auto text-center">
          <button
            type="submit"
            disabled={isLoading}
            className="btn btn-primary mx-auto col-4 rounded-pill"
          >
            {isLoading ? (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Process
              </>
            ) : (
              <>Submit</>
            )}
          </button>
        </div>
      </form>
    </>
  );
};

export default ForgotPassword;
