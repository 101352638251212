import React from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useTranslation } from "react-i18next";
import { BsXSquareFill } from "react-icons/bs";
import { connect } from "react-redux";
import { cartStore } from "../../redux/actions/storeActions";
import { ToastModal } from "../toast";
import history from "../../utils/history";
import { useMutation, useQuery } from "@tanstack/react-query";
import api from "../../utils/api";
import Cookies from "js-cookie";
import { queryClient } from "../..";
const GetVariation = ({ id, vDt, lang }) => {
  const { data: vList } = useQuery({
    queryKey: ["cartVariation", id],
    queryFn: async () => {
      let res = await api.get("/carts/getVariation", {
        params: { vDt },
      });
      return res.data;
    },
  });

  return (
    <>
      {vList?.map((e, index) => (
        <div key={index}>
          <p className="mb-0">
            -{e.attribute && e.attribute[`${lang}_name`]}
            <span className="text-dark fw-semibold">
              &nbsp;{e[`${lang}_name`]}
            </span>
          </p>
        </div>
      ))}
    </>
  );
};

const AddToCarts = ({ show, setShow, lang, cartS, cartStore, authd }) => {
  const { t } = useTranslation();

  const removeMutation = useMutation({
    mutationFn: (newTodo) => {
      if (newTodo.id) {
        return api.delete("/carts/delete", { params: newTodo });
      }
    },
    onSuccess: async (data, variables) => {
      const pCart = await Cookies.get("cart");
      if (pCart) {
        let pDt = JSON.parse(pCart);
        Cookies.set(
          "cart",
          JSON.stringify(pDt.filter((e) => e.id !== variables.id))
        );
      }
      queryClient.setQueryData(["cart_list"], (old) =>
        old ? old.filter((e) => e.id !== variables.id) : old
      );

      // queryClient.invalidateQueries("cart_list");
    },
  });

  const { data: List } = useQuery({
    queryKey: ["cart_list"],
    queryFn: async () => {
      const pCart = await Cookies.get("cart");
      if (pCart) {
        let pDt = JSON.parse(pCart);

        let res = await api.get("/carts/getAll", {
          params: { pDt: pDt?.map((e) => e.id) },
        });
        return res.data;
      }
    },
  });

  const { data: disc } = useQuery({
    queryKey: ["discount"],
    queryFn: async () => {
      let res = await api.get("/discounts/getActive");
      return res.data;
    },
  });

  const discountedPrice = (price, discount) => {
    return (price * discount) / 100;
  };

  return (
    <Offcanvas show={show} placement="end" onHide={() => setShow(false)}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Cart</Offcanvas.Title>
      </Offcanvas.Header>
      {List?.length > 0 && (
        <Offcanvas.Body>
          {List?.map((e, index) => (
            <div className="" key={index}>
              <div className="d-flex justify-content-between">
                <div>
                  <h6 className="mb-0">
                    -{e.product && e.product[`${lang}_name`]}
                  </h6>

                  <p className="mb-0">
                    -{t("Verification")}{" "}
                    <span className="text-dark fw-semibold">
                      {e.is_verification ? "Required" : "Not Required"}
                    </span>
                  </p>
                  <p className="mb-0">
                    -{t("Designer")}{" "}
                    <span className="text-dark fw-semibold">
                      {e.is_designer ? "Required" : "Not Required"}
                    </span>
                  </p>
                  {e.attribute_variation?.length > 0 && (
                    <GetVariation
                      id={e.id}
                      vDt={e.attribute_variation}
                      lang={lang}
                    />
                  )}
                </div>

                <div className="d-flex align-items-start">
                  <div>{e.price}$</div>
                  <button
                    type="button"
                    onClick={() => removeMutation.mutate({ id: e.id })}
                    className="p-0 m-0 ms-1 border-0 bg-transparent fs-5"
                  >
                    <BsXSquareFill />
                  </button>
                </div>
              </div>
              <hr />
            </div>
          ))}
          <div className="d-flex fw-bold align-items-center justify-content-between">
            <div>{t("sub_total")}</div>
            <div>
              {List?.map((e) => Number(e.price))?.reduce((a, b) => a + b, 0)}$
            </div>
          </div>
          {disc && (
            <>
              <div className="d-flex fw-bold align-items-center justify-content-between">
                <div>Discount-- {disc.discount}%</div>
                <div>
                  -
                  {discountedPrice(
                    List?.map((e) => Number(e.price))?.reduce(
                      (a, b) => a + b,
                      0
                    ),
                    disc.discount
                  )}
                  $
                </div>
              </div>
              <div className="d-flex fw-bold align-items-center justify-content-between">
                <div>{t("sub_total")}</div>
                <div>
                  {List?.map((e) => Number(e.price))?.reduce(
                    (a, b) => a + b,
                    0
                  ) -
                    discountedPrice(
                      List?.map((e) => Number(e.price))?.reduce(
                        (a, b) => a + b,
                        0
                      ),
                      disc.discount
                    )}
                  $
                </div>
              </div>
            </>
          )}
          <div className="text-center w-100 mt-5">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                if (!authd) {
                  ToastModal("Please Login first", "error");
                } else if (authd && authd.type === "admin") {
                  ToastModal("Please Login as a user account", "error");
                } else {
                  history.push("/payment");
                }
              }}
            >
              CheckOut
            </button>
          </div>
        </Offcanvas.Body>
      )}
    </Offcanvas>
  );
};
const mapStateToProps = (state) => {
  return {
    cartS: state.localStore.list,
  };
};
export default connect(mapStateToProps, { cartStore })(AddToCarts);
